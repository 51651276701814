import { Disclosure } from '@headlessui/react';
import {
  ChartPieIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  LockClosedIcon,
  SparklesIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React from 'react';

import { AudiencesIcon, ResearchIcon } from '@/src/app/_components/Icons';
import { Link } from '@/src/app/_components/ui/Link';
import { useFeatureFlagContext } from '@/src/features/feature-flags/context-provider/_components/FeatureFlagContext';

const WithLink = ({
  href,
  children,
  onClick,
  disabled = false,
}: {
  href?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  if (!href || disabled) return children;

  return (
    <Link
      href={href}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(true);
      }}
      preserveQuery={true}
      scroll={false}
    >
      {children}
    </Link>
  );
};

const SectionItem = ({
  isExpanded,
  name,
  disabled = false,
  href,
  setExpanded,
  ItemIcon,
  hidden = false,
}: {
  isExpanded: boolean;
  name: string;
  ItemIcon?: React.ElementType;
  disabled?: boolean;
  hidden?: boolean;
  href: string;
  setExpanded: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  const pathname = usePathname();

  if (hidden) return;

  return (
    <WithLink href={href} onClick={setExpanded} disabled={disabled}>
      <div
        className={clsx(
          'mt-2 flex w-full shrink-0 items-center gap-x-3 rounded-sm px-[18px] py-2 text-left hover:bg-active hover:text-primary',
          {
            'bg-active text-secondary-hover': pathname.startsWith(href),
            'cursor-not-allowed': disabled,
          },
        )}
      >
        {ItemIcon && (
          <ItemIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
        )}
        {isExpanded && name}
        {isExpanded && disabled && (
          <LockClosedIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
        )}
      </div>
    </WithLink>
  );
};

const NavItem = ({
  name,
  href,
  hidden = false,
  starred = false,
}: {
  href?: string;
  name: string;
  hidden?: boolean;
  starred?: boolean;
}) => {
  const pathname = usePathname();

  if (hidden) return;

  return (
    <WithLink href={href}>
      <div
        className={clsx(
          'w-100 mt-2 flex items-center overflow-hidden whitespace-nowrap rounded-sm px-4 py-2 leading-6',
          {
            'text-gray-6': !href,
            'hover:bg-active hover:text-primary': !!href,
            'bg-active text-secondary-hover': href && pathname.startsWith(href),
          },
        )}
      >
        {name}
        {starred && (
          <StarIcon className="ml-auto h-5 w-5 shrink-0 fill-yellow text-yellow" />
        )}
      </div>
    </WithLink>
  );
};

const Button = ({
  name,
  href,
  hidden = false,
}: {
  href?: string;
  name: string;
  hidden?: boolean;
}) => {
  if (hidden) return;

  return (
    <WithLink href={href}>
      <div
        className={clsx(
          'w-100 mt-2 block cursor-pointer overflow-hidden whitespace-nowrap rounded-sm border border-gray-8 px-3 py-2 text-center leading-6 hover:text-primary',
        )}
      >
        {name}
      </div>
    </WithLink>
  );
};

const Section = ({
  isExpanded,
  name,
  children,
  href,
  setExpanded,
  ItemIcon,
  hidden = false,
}: {
  isExpanded: boolean;
  name: string;
  ItemIcon?: React.ElementType;
  hidden?: boolean;
  children?: React.ReactNode;
  href: string;
  setExpanded: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  const pathname = usePathname();

  if (hidden) return;

  return (
    <Disclosure as="div" defaultOpen={pathname.startsWith(href)}>
      {({ open }) => (
        <>
          <Disclosure.Button
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(true);
            }}
            className="w-full"
          >
            <div
              className={clsx(
                'mt-2 flex w-full shrink-0 items-center gap-x-3 rounded-sm px-4 py-2 text-left hover:bg-active hover:text-primary',
                {
                  'bg-active text-primary': pathname.startsWith(href),
                },
              )}
            >
              {ItemIcon && (
                <ItemIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              )}
              <span className="truncate">{isExpanded && name}</span>
              {children && (
                <ChevronDownIcon
                  className={clsx(
                    'ml-auto h-5 w-5 shrink-0 text-secondary transition-all',
                    { 'rotate-180': open },
                  )}
                  aria-hidden="true"
                />
              )}
            </div>
          </Disclosure.Button>
          {children && isExpanded && (
            <Disclosure.Panel>
              <div className="ml-9">{children}</div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
};

export const SidebarNav = ({
  isExpanded,
  setExpanded,
  isAdmin,
}: {
  isExpanded: boolean;
  isAdmin: boolean;
  setExpanded: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  const { enabledFeatures } = useFeatureFlagContext();
  const expansionProps = { isExpanded, setExpanded };

  return (
    <div
      className={clsx('flex h-full flex-col px-2 text-tertiary', {
        'w-full': isExpanded,
        'mt-[1px]': !isExpanded,
      })}
    >
      <Section
        ItemIcon={ChartPieIcon}
        name="Starter"
        href="/share"
        {...expansionProps}
      >
        <NavItem name="Overview" href="/share/overview" />
        <NavItem
          name="Wallet Share"
          href="/share/wallet-share"
          hidden={!enabledFeatures.includes('2024-12-wallet-share-tab')}
        />
        <Section
          name="Overlap"
          href="/share/overlap"
          hidden={!enabledFeatures.includes('2024-12-overlap-tab')}
          {...expansionProps}
        >
          <NavItem name="Competitors" href="/share/overlap/competitors" />
          <NavItem name="Affinities" href="/share/overlap/affinities" />
        </Section>
        <NavItem
          name="Journey"
          href="/share/journey"
          hidden={!enabledFeatures.includes('2024-11-journey-tab')}
        />
        <NavItem
          name="Spendalike™"
          href="/share/spendalike"
          hidden={!enabledFeatures.includes('2024-09-spendalike-tab')}
        />
        <NavItem name="Acquisition" href="/share/acquisition" />
        <Section name="Loyalty" href="/share/loyalty" {...expansionProps}>
          <NavItem name="Total" href="/share/loyalty/total" />
          <NavItem name="Day of Week" href="/share/loyalty/day-of-week" />
          <NavItem name="ATV" href="/share/loyalty/atv" />
          <NavItem
            name="Repeat Customers"
            href="/share/loyalty/repeat-customers"
          />
        </Section>
      </Section>
      <SectionItem
        ItemIcon={AudiencesIcon}
        name="Audiences"
        disabled={!isAdmin}
        href="/audience"
        {...expansionProps}
      />
      <SectionItem
        ItemIcon={ResearchIcon}
        name="Research"
        disabled={!isAdmin}
        href="/research"
        {...expansionProps}
      />
      <Section
        ItemIcon={AudiencesIcon}
        name="Target"
        href="/target"
        hidden={!isAdmin}
        {...expansionProps}
      />
      <Section
        ItemIcon={ChartPieIcon}
        name="Strategy"
        href="/strategy"
        hidden={!isAdmin}
        {...expansionProps}
      />
      <Section
        ItemIcon={SparklesIcon}
        name="Projects"
        href="/projects"
        hidden={!isAdmin}
        {...expansionProps}
      >
        <Button name="Create New Project" />
        <NavItem name="Agents" />
        <Section
          name="Marketing Strategy & Acquisition"
          href="/projects"
          {...expansionProps}
        >
          <NavItem
            name="Personas"
            href="/projects/5245f0a5-7d62-4e6c-8051-1f5c42775279"
            starred
          />
          <NavItem
            name="Matched Market Testing"
            href="/projects/380a159e-b6c4-4e79-8616-936d4aef7080"
          />
          <NavItem
            name="Market Prioritization"
            href="/projects/d2418f04-d639-4937-af66-d4fceac9d041"
          />
          <NavItem
            name="Agency Evaluation"
            href="/projects/7af95f2b-66a5-480b-b134-ecc519dae04b"
          />
          <NavItem
            name="C-Suite & Board Reporting"
            href="/projects/9310b123-f212-455c-a409-d44470169b83"
          />
          <NavItem
            name="Audience Exploration"
            href="/projects/4e70ab65-b6b9-4647-9f42-9d7c92f53a0a"
          />
          <NavItem
            name="Local Market Analysis"
            href="/projects/5f8dbd79-74be-4d39-8e17-72cbb9fadbcd7"
          />
          <NavItem
            name="Competitor SWOT"
            href="/projects/558b8f39-b3d5-460f-a4d5-b8e062cde200"
          />
        </Section>
        <Section
          name="Pricing & Promotions"
          href="/projects1/"
          {...expansionProps}
        >
          <NavItem name="Market Prioritization" href="/projects1/11" />
        </Section>
        <Section name="Site Selection" href="/projects2" {...expansionProps}>
          <NavItem name="Site Evaluation" href="/projects2/21" />
        </Section>
      </Section>
      <Section
        ItemIcon={Cog8ToothIcon}
        name="Admin"
        href="/admin"
        hidden={!isAdmin}
        {...expansionProps}
      >
        <NavItem name="Login As" href="/admin/login-as" />
        <NavItem name="Brands" href="/admin/brands/all" />
        <NavItem name="Tooltips" href="/admin/tooltips" />
        <NavItem name="Feature Flags" href="/admin/feature-flags" />
        <NavItem name="Chat History" href="/admin/chat-history" />
        <NavItem name="Diagnostics" href="/admin/diagnostics" />
      </Section>
    </div>
  );
};
