'use client';

import BaseLink from 'next/link';
import { useSearchParams } from 'next/navigation';
import { MouseEventHandler, useCallback } from 'react';

export const Link = ({
  children,
  href,
  preserveQuery = false,
  scroll,
  onClick,
  className,
  queryParams,
}: {
  active?: boolean;
  children: React.ReactNode;
  href: string;
  preserveQuery?: boolean;
  scroll?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  queryParams?: Record<string, any>;
}) => {
  const query = useSearchParams();

  const createQueryString = useCallback(() => {
    const params = new URLSearchParams(query.toString());

    Object.entries(query).forEach(([key, value]) => {
      params.set(key, value);
    });

    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        params.set(key, value);
      });
    }

    return params.toString();
  }, [queryParams, query]);

  return (
    <BaseLink
      href={preserveQuery && query ? href + '?' + createQueryString() : href}
      scroll={scroll}
      className={className}
      onClick={onClick}
    >
      {children}
    </BaseLink>
  );
};
